/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isArray } from "lodash";
import moment from "moment";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import CrossSVF from "../../../assets/media/icons/standard_icons/cross.svg";
import WarningSVG from "../../../assets/media/icons/standard_icons/warning1.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
// import TablePopup from "../../../components/tableComponent/TablePopup";
import _ from "lodash";
import CloneSquareSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import DeleteSquareSVG from "../../../assets/media/icons/other_icons/Delete.svg";
import backToTOp from "../../../assets/media/icons/other_icons/backtotop.svg";
import CloseSquareSVG from "../../../assets/media/icons/other_icons/close_square.svg";
import EditSquareSVG from "../../../assets/media/icons/other_icons/edit-square.svg";
import processSVG from "../../../assets/media/icons/other_icons/process.svg";
import SaveSVG from "../../../assets/media/icons/other_icons/save.svg";
import ListingLinesSVG from "../../../assets/media/icons/standard_icons/listing_lines.svg";

import { Tooltip } from "react-tooltip";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  decimalNumber,
  existInSessionstorage,
  removeCommaFromNumber,
  sentanceCase,
} from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  generateUniqueId,
  TicketError,
  visiblityCountForPayload,
} from "../../add_inventory/core/_functions";
import CollapseButton from "../components/CollapseButton";
import { InventoryContext } from "../core/InventoryProvider";
import {
  bulkPayloadCreate,
  bulkUpdate,
  bulkUploadCount,
  calculateAvgPriceForProceedValue,
  changedInputValues,
  changedSelectedCount,
  processInBatches,
  setEventRecordPayload,
  visibilityMeterCalculationForInventory,
} from "../core/_functions";
import { Event, EventFields } from "../core/_models";
import {
  getInventoryRecordListing,
  handleBulk,
  UpdateInventory,
} from "../core/_request";

const isEventPublishWithTickets = (eventRecord: any) =>
  Number(eventRecord?.quantity_available) > 0 &&
  Number(eventRecord?.quantity_available) ===
    (Number(eventRecord?.listing_ticket_files?.length) ||
      Number(eventRecord?.mobile_links?.length)) &&
  eventRecord?.status === "yes";

const handleScrollProceedPriceField =
  (events: any = [], uploadedFields: any = []) =>
  () => {
    // get all  selected event records events id for proceed field id
    let proceedFieldIds = events
      .map((event: any) => {
        if (
          event?.eventRecords?.some(
            (item: any) => uploadedFields.indexOf(item) !== -1
          )
        ) {
          let selectedFirstFieldIndex = event?.eventRecords.findIndex(
            (rec: any) => rec.selected
          );
          return `${event.id}${selectedFirstFieldIndex}sell_price`;
        }
        return "";
      })
      .filter(Boolean);

    if (
      proceedFieldIds &&
      Array.isArray(proceedFieldIds) &&
      proceedFieldIds.length > 0
    )
      proceedFieldIds.forEach((proceedFieldId: any) => {
        let proceedField: any = document.getElementById(proceedFieldId);
        if (proceedField)
          proceedField.scrollIntoView({
            behavior: "auto",
            inline: "center",
            block: `center`,
          });
      });
  };

const Footer = memo(
  ({
    list,
    setList,
    publishStatus,
    currentRecordId,
    setCurrentRecordId,
    query,
    loadMoreListing,
    isOpenTicketPopUp,
  }: any) => {
    const btnRef: any = useRef(null);
    const layout = useContext(InventoryContext);
    const coreLayout = useContext(LayoutContext);
    const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
    const isTableEditable = layout.isTableEditable;
    const events: any = list && list.length > 0 ? list : [];
    const eventLength = list && list.length > 0;
    const [tableAlert, setTableAlert] = useState(false);
    const [tableAlertMsg, setTableAlertMsg] = useState("");
    const [tableAlertConfirm, setTableAlertConfirm] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [listingCount, setListingCount] = useState<any>();
    const [flag, setFlag] = useState(false);
    const [loader, setLoader] = useState(false);
    const [publishedAlertMsg, setPublishedAlertMsg] = useState<any>();
    const [updateAllFlag, setUpdateAllFlag] = useState<any>(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const listingIds = coreLayout?.editListings;

    const conversation_rate = coreLayout?.conversationRate;

    const bulkTableChangedFields = useMemo(() => {
      const flattenedFields = _.uniq(_.flatten(layout.tableDataEditChanging));
      const deliveryIds = _.map(layout?.deliveryType, "id");
      const filteredFields = _.difference(flattenedFields, deliveryIds);

      return _.compact(filteredFields).map((item: string) =>
        sentanceCase(item.replace(/_/g, " "))
      );
    }, [layout.tableDataEditChanging, layout.deliveryType]);

    const navigate = useNavigate();
    const [selectAllLoader, setSelectAllLoader] = useState<boolean>(false);
    const [checkEventIsOpen, setCheckEventIsOpen] = useState<any>([]);

    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    let publishStatusId =
      publishStatus &&
      Array.isArray(publishStatus) &&
      publishStatus.length > 0 &&
      _.map(publishStatus, "id");
    const [isVisible, setIsVisible] = useState(false);
    // Function to scroll back to the top
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Function to show/hide the button based on scroll position
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      // Show the button when the user scrolls down 400px
      if (scrollPosition > 250) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    // Add event listener when the component mounts
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    // CHANGED RECORD COUNT
    let changedDataCount = events?.reduce((count: any, event: any) => {
      const changedRecords = event?.eventRecords?.filter(
        (record: any) => record?.isInputChange
      );
      return count + (changedRecords ? changedRecords.length : 0);
    }, 0);

    // SELECTED COUNT OF RECORDS
    // let changedSelectedCount = events?.reduce((count: any, event: any) => {
    //   const selectedCount = event?.eventRecords?.filter(
    //     (record: any) => record?.selected
    //   );
    //   return count + (selectedCount ? selectedCount.length : 0);
    // }, 0);

    const eventCountSelected = events?.filter(
      (obj: any) =>
        obj?.eventRecords &&
        obj?.eventRecords?.some((item: any) => item?.selected)
    );

    const [openEvent, setOpenEvent] = useState<any>();

    useEffect(() => {
      const fetchData = async () => {
        const openedEventCount = await events?.reduce(
          async (countPromise: any, event: any) => {
            const eventListing: any = document.getElementById(event?.id);

            if (
              eventListing &&
              eventListing.getAttribute("aria-expanded") === "true" &&
              event?.eventRecords
            ) {
              const { count, listingsCount } = await countPromise;
              const eventTotalListings = event?.total_listings || 0;

              return {
                count: count + 1,
                listingsCount: listingsCount + eventTotalListings,
              };
            } else {
              return await countPromise;
            }
          },
          Promise.resolve({ count: 0, listingsCount: 0 })
        );

        setOpenEvent(openedEventCount);

        if (openedEventCount?.listingsCount === 0) {
          layout.setSelectAllConfirmation(false);
        }
      };
      if (events?.length > 0) {
        fetchData();
      }
    }, [events]);

    let isLoaderActive = events?.reduce((count: any, event: any) => {
      const selectedCount = event?.eventRecords?.filter(
        (record: any) => record?.loader
      );
      return count + (selectedCount ? selectedCount.length : 0);
    }, 0);

    let allIDs: any = existInSessionstorage("checkedIds");

    let isDisabled =
      (allIDs && _.size(allIDs) > 0) ||
      layout.isBulkActionLoader ||
      !eventLength ||
      (layout?.tableAlert?.confirm && _.size(layout?.tableAlert?.confirm) > 0)
        ? true
        : false ||
          updateAllFlag ||
          selectAllLoader ||
          bulkUploadCount(list) > 0;

    // COUNT OF LISTING
    let countTotalListing = events?.reduce((count: any, event: any) => {
      const innerCount = event?.eventRecords;
      return count + (innerCount ? innerCount.length : 0);
    }, 0);

    //send response to eventField component
    function refreshEventsWhileActionPerform() {
      //send response to eventField component
      const tempEvents = (events: Array<Event>) => {
        return events.map((event) => {
          return {
            ...event,
            eventRecords:
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
                ? event["eventRecords"].map((eventRecord: any) => {
                    return {
                      ...eventRecord,
                    };
                  })
                : undefined,
          };
        });
      };
      setList(tempEvents([...events]));
    }

    // RESET EDIT STATE
    useEffect(() => {
      if (
        changedSelectedCount(events) === 0 &&
        isTableEditable &&
        _.size(isTableEditable) > 0
      ) {
        layout.setIsTableEditable([]);
        layout.setTableDataEditChanging([]);
        refreshEventsWhileActionPerform();
      }

      if (changedSelectedCount(events) === 0 && layout.isDeleteActive) {
        layout.SetIsDeleteActive(false);
      }
    }, [changedSelectedCount(events)]);

    useEffect(() => {
      layout.setRefreshList(Date.now());
    }, [list]);

    // CHECKBOX COUNT
    const getCheckboxCount = ({ action }: any) => {
      const events: Array<Event> = list;
      let rowSelected = 0;
      let eventSelected: any = [];
      events.map((event) => {
        return Array.isArray(event["eventRecords"])
          ? event["eventRecords"].map((eventRecord: EventFields) => {
              if (eventRecord?.selected === true) {
                rowSelected++;

                if (action === "edit") {
                  let tempcountSelectedEditEvents: any = [];
                  if (Array.isArray(layout.countSelectedEdit.events)) {
                    tempcountSelectedEditEvents = [
                      ...layout.countSelectedEdit.events,
                    ];
                  }
                  if (eventSelected.indexOf(event.id) === -1) {
                    eventSelected.push(event.id);
                  }
                }
              }
            })
          : [];
      });
      if (action === "edit") {
        layout.setCountSelectedEdit({
          ...layout.countSelectedEdit,
          rows: rowSelected,
          events: eventSelected.length,
        });
      }

      return rowSelected;
    };

    // Select All Events
    const handleSelectAll = (data: any, deselect: boolean) => {
      countTotalListing > 40 && setSelectAllLoader(true);

      const tempEvents = (events: Array<Event>) => {
        return events.map((event: any) => {
          const eventListing: any = document.getElementById(event?.id);

          if (
            eventListing &&
            eventListing.getAttribute("aria-expanded") === "true"
          ) {
            return {
              ...event,
              requireSelectAll: {},
              eventRecords:
                Array.isArray(event["eventRecords"]) &&
                event["eventRecords"].length > 0
                  ? event["eventRecords"].map((eventRecord: any) => {
                      return {
                        ...eventRecord,
                        selected: false,
                      };
                    })
                  : undefined,
            };
          } else return event;
        });
      };
      setSelectAll(data.target.checked);
      setList(tempEvents([...events]));

      setTimeout(() => {
        setSelectAllLoader(false);
      }, 3000);
    };

    // SELECT ALL EVENT LISTINGS
    const handleLoadAllListing = async (e: any) => {
      setCurrentRecordId(undefined);
      // MAKE RECORD DISABLED
      events?.forEach((event: any) => {
        const eventListing: any = document.getElementById(event?.id);
        if (
          eventListing &&
          eventListing.getAttribute("aria-expanded") === "true" &&
          event?.meta
        ) {
          event?.eventRecords?.forEach((record: any) => {
            record.bulkLoader = true;
          });
        }
      });

      let updatedEvents = await Promise.all(
        events.map(async (event: any) => {
          const eventListing: any = document.getElementById(event?.id);
          delete event?.requireSelectAll;

          if (
            eventListing &&
            eventListing.getAttribute("aria-expanded") === "true" &&
            event?.meta &&
            (event?.requireSelectAll && _.size(event?.requireSelectAll) > 0
              ? false
              : true)
          ) {
            layout.setSelectAllLoader(true);

            layout.setSelectAllLoaderCurrentEvent(event?.id);
            let userId =
              layout.users &&
              Array.isArray(layout.users) &&
              layout.users.length > 0 &&
              _.map(layout.users, "id");
            let meta = event?.["meta"];
            let currentPage =
              meta["current_page"] > 1 ? 1 : meta["current_page"];
            let lastPage = meta["last_page"];
            let count: any = [];

            for (currentPage; currentPage <= lastPage; currentPage++) {
              count.push(currentPage);
            }

            let eventRecords: any = [];
            let eventMeta: any;

            // Make additional API calls for events with count greater than 1
            for (let i = 0; i < count.length; i++) {
              let eventListing: any = await getInventoryRecordListing({
                id: event?.id,
                userId: userId,
                publishStatusId,
                teamsSelected: layout?.teamSelectedGlobal,
                orderState: layout.orderState,
                page: count[i], // Assuming your API supports pagination
                per_page: 50,
                query,
                newInventoryFilter: layout.inventoryNewFilterValue,
                listingIds,
              }).then((response) => {
                return response;
              });

              // Append data to eventRecords
              eventRecords = eventRecords.concat(
                eventListing?.data?.map((item: any) => {
                  let visibility: any = visibilityMeterCalculationForInventory(
                    event,
                    item,
                    layout.ticketType
                  );
                  const avg_price = calculateAvgPriceForProceedValue(
                    item?.section,
                    item?.markinsight,
                    item,
                    event,
                    conversation_rate,
                    isGodAdmin
                  );

                  return {
                    ...item,
                    selected: item?.processing
                      ? false
                      : item?.hold_tickets?.length
                      ? false
                      : true,
                    bulkLoader: false,
                    ...(isGodAdmin && { isLock: false }),
                    benefits: item?.restrictions,
                    ticketsInHands: item?.in_hand === "no" ? false : true,
                    visibility: Math.round(visibility),
                    oldVisibility: 0,
                    exchanges: isArray(event?.selected_event_exchanges)
                      ? event?.selected_event_exchanges
                      : [],
                    avg_price: avg_price,
                  };
                })
              );
              eventMeta = eventListing?.meta;
            }
            let tempRecords = _.uniqBy(
              [
                ...event?.eventRecords?.map((record: any) => {
                  return {
                    ...record,
                    selected: record?.processing
                      ? false
                      : record?.hold_tickets?.length
                      ? false
                      : true,
                    bulkLoader: false,
                    ...(isGodAdmin && { isLock: false }),
                    benefits: record?.restrictions,
                    ticketsInHands: record?.in_hand === "no" ? false : true,
                    // visibility: Math.round(visibility),
                    oldVisibility: 0,
                    exchanges: isArray(event?.selected_event_exchanges)
                      ? event?.selected_event_exchanges
                      : [],
                  };
                }),
                ...eventRecords,
              ],
              "id"
            );

            let final = {
              ...event,
              eventRecords: tempRecords,
              eventLoader: false,
              requireSelectAll: {},
            };
            delete final.meta;

            return final;
          } else if (
            eventListing &&
            eventListing.getAttribute("aria-expanded") === "true"
          ) {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map((record: any) => {
                return {
                  ...record,
                  selected: record?.processing
                    ? false
                    : record?.hold_tickets?.length > 0
                    ? false
                    : true,
                  bulkLoader: false,
                };
              }),
            };
          } else {
            return event;
          }
        })
      );

      layout.setSelectAllLoader(false);
      layout.setSelectAllLoaderCurrentEvent({});
      layout.setSelectAllConfirmation(false);
      layout.setRefreshList(Date.now());
      refreshEventsWhileActionPerform();
      setList(updatedEvents);
      setSelectAll(true);
    };

    // DELETE row with ENTER Button
    useEffect(() => {
      const keyDownHandler = (event: any) => {
        if (event.key === "Enter" && btnRef && btnRef.current !== null) {
          btnRef.current.click();
          setTableAlert(false);
          setFlag(true);
        }
      };
      if (flag === true) {
        setFlag(false);
      }
      document.addEventListener("keydown", keyDownHandler);
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [flag]);

    useEffect(() => {
      if (
        isArray(coreLayout.cloneEvents) &&
        coreLayout.cloneEvents.length > 0
      ) {
        navigate("/add-inventory");
      }
    }, [coreLayout.cloneEvents]);

    // DELETE EVENT-RECORD
    const handleEventFieldsDelete = () => {
      const events: Array<Event> = list;

      let countSelected = 0;
      let published = 0;
      let unpublished = 0;
      events.map((event) => {
        return Array.isArray(event["eventRecords"])
          ? event["eventRecords"].map((eventRecord: EventFields) => {
              if (eventRecord?.selected === true) {
                countSelected++;
              }
              if (
                eventRecord?.selected === true &&
                eventRecord?.status === "yes"
              ) {
                published++;
              }
              if (
                eventRecord?.selected === true &&
                eventRecord?.status === "no"
              ) {
                unpublished++;
              }
            })
          : [];
      });
      if (countSelected > 0) {
        layout.SetIsDeleteActive(true);
        setTableAlert(true);
        setTableAlertMsg(
          `Delete ${countSelected} listing${countSelected > 1 ? "s" : ""}? `
        );
        setPublishedAlertMsg(
          `${published > 0 ? published + " Published" : ""}${
            published && unpublished ? "," : ""
          } ${unpublished > 0 ? +unpublished + " Unpublished" : ""}`
        );

        setTableAlertConfirm(true);
        refreshEventsWhileActionPerform();

        setList((prevList: any) => {
          return prevList?.map((obj: any) => {
            const totalSelectedCount = obj?.eventRecords?.reduce(
              (count: any, eventRecord: any) =>
                eventRecord.selected ? count + 1 : count,
              0
            );

            let final = { ...obj, totalSelectedCount };
            delete final?.requireSelectAll;
            return final;
          });
        });
      } else {
        setTableAlert(true);
        setTableAlertMsg("Select a row to Delete");
        setTableAlertConfirm(false);
      }
    };

    useEffect(() => {
      let allIDs: any = existInSessionstorage("checkedIds");
      if (allIDs) sessionStorage.removeItem("checkedIds");
    }, []);
    const [deleteResponse, setDeleteResponse] = useState<any>(false);
    // CONFIRM DELETE
    const confirmEventFieldsDelete = _.debounce(() => {
      layout.setUpdateAllLoader(true);

      const alertMessage = `${changedSelectedCount(
        events
      )} listing(s) have been scheduled for deletion`;
      showAlert(alertMessage, false);

      const checkedIds = list
        .flatMap((event: any) =>
          Array.isArray(event["eventRecords"])
            ? event["eventRecords"].filter(
                (eventRecord) => eventRecord?.selected === true
              )
            : []
        )
        .map((eventRecord: any) => eventRecord.id);

      // SET CHECK ID IN LOCALSTORAGE
      setList((events: any) => {
        return events?.map((event: any) => {
          let allSelectedlisting = event?.eventRecords?.filter(
            (record: any) => record.selected
          );
          const countOfQuantityAvailable = allSelectedlisting?.reduce(
            (count: number, record: any) =>
              count + (record?.quantity_available || 0),
            0
          );
          const countOfStatusYes =
            allSelectedlisting?.filter((record: any) => record.status === "yes")
              ?.length || 0;

          const countOfStatusNo =
            allSelectedlisting?.filter((record: any) => record.status === "no")
              ?.length || 0;

          let total_listings = allSelectedlisting?.length
            ? event?.total_listings - allSelectedlisting?.length
            : event?.total_listings;

          let total_qty_available = countOfQuantityAvailable
            ? event?.total_qty_available - countOfQuantityAvailable
            : event?.total_qty_available;

          let total_unpublished_listings = countOfStatusNo
            ? event?.total_unpublished_listings - countOfStatusNo
            : event?.total_unpublished_listings;

          let total_published_listings = countOfStatusYes
            ? event?.total_published_listings - countOfStatusYes
            : event?.total_published_listings;

          return {
            ...event,
            eventRecords: event?.eventRecords?.map((record: any) => {
              if (record?.selected) {
                return {
                  ...record,
                  deleteLoader: true,

                  // fadeOut: true,
                };
              } else {
                return record;
              }
            }),

            total_listings: total_listings,
            total_qty_available: total_qty_available,
            total_unpublished_listings: total_unpublished_listings,
            total_published_listings: total_published_listings,
          };
        });
      });

      let timeoutId;

      // Set the timeout and save the ID
      timeoutId = setTimeout(() => {
        setList((events: any) => {
          let final = events?.map((event: any) => {
            return {
              ...event,
              eventRecords: event?.eventRecords?.filter(
                (record: any) => !record.deleteLoader
              ),
            };
          });
          final = final?.filter((event: any) => event?.total_listings > 0);
          layout.setUpdateAllLoader(false);

          return final;
        });
        setDeleteResponse(true);
      }, 1000);

      // refreshEventsWhileActionPerform();

      // BULK DELETE HANDLE API
      handleBulk({ checked_ids: checkedIds, action: "delete" }).then(
        (response: any) => {
          if (Object.keys(response?.data?.errors).length === 0) {
            // showAlert(response?.message, false);
          } else {
            Object.values(response?.data?.errors).forEach((error: any) => {
              showAlert(error, true);
            });
          }
          if (response) {
            // NEW CODE FETCHING NEW RECORD
            timeoutId = setTimeout(async () => {
              let recordsToLoad: any = [];

              setList((events: any) => {
                let updatedEvents = events?.map((event: any) => {
                  return {
                    ...event,
                    eventRecords: event?.eventRecords?.filter(
                      (record: any) => !record.deleteLoader
                    ),
                  };
                });

                let eventsWithEmptyRecords = updatedEvents?.filter(
                  (event: any) =>
                    event?.total_listings > 0 &&
                    event?.eventRecords?.length === 0
                );

                recordsToLoad.push(...eventsWithEmptyRecords);

                return updatedEvents;
              });

              if (recordsToLoad.length > 0) {
                // Assuming loadMoreListing returns a Promise
                await Promise.all(
                  recordsToLoad.map((obj: any) =>
                    loadMoreListing(obj, "pusher")
                  )
                );
              }
            }, 100);
          }
        }
      );
    }, 100);

    function checkIsFullfillUploadExist() {
      const selectedEventRecords: any = list
        ?.flatMap((item: any) =>
          item?.eventRecords?.filter((record: any) => record?.selected)
        )
        .filter((obj: any) => obj !== undefined);

      const flag =
        selectedEventRecords?.length > 0
          ? selectedEventRecords?.filter(
              (obj: any) =>
                Number(obj?.quantity_available) ===
                  Number(
                    obj?.listing_ticket_files?.length ||
                      obj?.mobile_links?.length
                  ) && obj?.status === "yes"
            )
          : [];
      return flag || "";
    }

    // EDIT SELECTED EVENTS
    const handleEditEvent = (data: any, deselect: boolean) => {
      if (getCheckboxCount({ action: "edit" }) > 0 === false) {
        setTableAlert(true);
        setTableAlertMsg("Select at least one row to edit");
        setTableAlertConfirm(false);
      } else {
        // if (isGodAdmin && checkPPEExist(list, isGodAdmin)?.teamNameError) {
        //   showAlert(
        //     checkPPEExist(list, isGodAdmin)?.teamNameError
        //       ? Messages?.bulkEditRestrictDiffTeam
        //       : Messages?.bulkEditRestrictPPE,
        //     true
        //   );
        //   return 0;
        // } else {
        const isUploaded = checkIsFullfillUploadExist();
        layout.setIsTableEditable(_.cloneDeep(list));
        //send response to eventField component
        const tempEvents = (events: Array<Event>) => {
          return events.map((event) => {
            return {
              ...event,
              eventRecords:
                Array.isArray(event["eventRecords"]) &&
                event["eventRecords"].length > 0
                  ? event["eventRecords"].map((eventRecord: any) => {
                      let isDisabled = event["eventRecords"].some(
                        (rec) => isEventPublishWithTickets(rec) && rec?.selected
                      );

                      return {
                        ...eventRecord,
                        isDisabled: isDisabled,
                      };
                    })
                  : undefined,
            };
          });
        };

        setList(tempEvents([...events]));

        if (isUploaded?.length > 0) {
          showAlert(
            "Your selection includes listings with tickets uploaded. Editing restricted to Proceed price, Clone, Delete and Publish status.",
            2
          );
          if (layout?.proceedPriceToggle) return;
          setTimeout(handleScrollProceedPriceField(list, isUploaded), 1000);
        }
      }
    };

    // HANDLE CLONE EVENTS
    const handleCloneEvent = () => {
      const events: Array<Event> = list;
      let countSelected = 0;
      events.map((event) => {
        return Array.isArray(event["eventRecords"])
          ? event["eventRecords"].map((eventRecord: EventFields) => {
              if (eventRecord?.selected === true) {
                countSelected++;
              }
            })
          : [];
      });
      if (countSelected > 0) {
        coreLayout.setCloneEvents((current: any) => {
          const tempEvents = list
            .map((event: any) => {
              const markInsight = event["eventRecords"]?.[0]?.markinsight;
              return {
                ...event,
                markeInsightsForAvgPrice: markInsight,
                extraTicketType: layout?.ticketType?.filter(
                  (item: any) => item?.id === "eticket"
                ),
                eventRecords: (Array.isArray(event["eventRecords"])
                  ? event["eventRecords"].map((eventRecord: any) => {
                      if (eventRecord?.selected === true) {
                        delete eventRecord?.additionalFile;
                        delete eventRecord?.template;
                        delete eventRecord?.inputs;
                        delete eventRecord?.templateKeys;

                        let category = event?.categories?.find(
                          (category: any) =>
                            category?.id == eventRecord?.category ||
                            category?.id == eventRecord?.category?.id
                        );

                        // let tickeType = layout?.ticketType?.find(
                        //   (ticketType: any) =>
                        //     ticketType?.id == eventRecord?.ticket_type ||
                        //     ticketType?.id == eventRecord?.ticket_type?.id
                        // );

                        let tickeType = layout?.ticketType?.find(
                          (ticketType: any) => {
                            if (eventRecord?.ticket_type === "eticket") {
                              return (
                                ticketType?.id == eventRecord?.sub_ticket_type
                              );
                            } else {
                              return (
                                ticketType?.id ==
                                  eventRecord?.ticket_type?.id ||
                                ticketType?.id == eventRecord?.ticket_type
                              );
                            }
                          }
                        );

                        // console.log(tickeType, "tickeType");
                        let deliveryPrices: any = {};
                        let deliverOptions = eventRecord?.delivery_options.map(
                          (option: any) => {
                            if (option?.price !== "") {
                              deliveryPrices = {
                                ...deliveryPrices,
                                [option?.id]:
                                  eventRecord?.[option?.id] || option?.price,
                              };
                            }
                            return {
                              ...option,
                              type: layout.deliveryType?.find(
                                (type: any) => type?.id == option?.id
                              )?.type,
                            };
                          }
                        );

                        const ticketFiles: any = [];
                        for (
                          let i = 0;
                          i < eventRecord?.quantity_available;
                          i++
                        ) {
                          ticketFiles.push({});
                        }

                        let visibility: any =
                          visibilityMeterCalculationForInventory(
                            event,
                            {
                              ...eventRecord,
                              ticketFiles,
                              listing_ticket_files: [],
                              mobile_links: [],
                            },
                            layout.ticketType
                          );

                        const faceValue = {
                          name: "faceValue",
                          value: (() => {
                            const faceValue =
                              removeCommaFromNumber(
                                eventRecord?.face_value?.value
                              ) ??
                              removeCommaFromNumber(eventRecord?.face_value);
                            if (faceValue) {
                              const numericValue = Number(faceValue);
                              if (numericValue > 0 && faceValue !== "") {
                                return decimalNumber(faceValue);
                              }
                            }
                            return "";
                          })(),
                          symbol:
                            eventRecord?.face_value?.symbol ??
                            eventRecord?.face_value_currency,
                        };

                        const proceedValue = {
                          name: "proceedValue",
                          value: (() => {
                            const sellPrice =
                              removeCommaFromNumber(
                                eventRecord?.sell_price?.value
                              ) ??
                              removeCommaFromNumber(eventRecord?.sell_price);

                            if (sellPrice) {
                              const numericValue = Number(sellPrice);
                              if (numericValue > 0 && sellPrice !== "") {
                                return decimalNumber(sellPrice);
                              }
                            }
                            return "";
                          })(),
                          symbol:
                            eventRecord?.sell_price?.symbol ??
                            eventRecord?.sell_price_currency,
                        };

                        return {
                          ...eventRecord,
                          deliveryType: deliverOptions,
                          ...deliveryPrices,
                          selected: false,
                          category: category,
                          ticketType: tickeType,
                          ticketFiles: ticketFiles,
                          // sub_ticket_type: tickeType?.subtypes.find(
                          //   (subType: any) =>
                          //     subType?.id == eventRecord?.sub_ticket_type ||
                          //     subType?.id == eventRecord?.sub_ticket_type?.id
                          // ),
                          quantity: eventRecord?.quantity_available,
                          maxDisplayQuantity: eventRecord?.quantity_display,
                          splitType: layout?.splitType?.find(
                            (splitType: any) =>
                              splitType?.id == eventRecord?.split_type ||
                              splitType?.id == eventRecord?.split_type?.id
                          ),
                          MULTIPLES: eventRecord?.split_quantity,
                          section: category?.sections?.find(
                            (section: any) =>
                              section?.id == eventRecord?.section ||
                              section?.id == eventRecord?.section?.id
                          ),
                          firstSeat: eventRecord?.first_seat,
                          faceValue: faceValue,
                          proceedValue: proceedValue,
                          dateToShip: eventRecord?.date_to_ship
                            ? moment(eventRecord?.date_to_ship).toDate()
                            : "",
                          benefits: layout?.benefits.filter(
                            (benefit: any) =>
                              eventRecord.benefits?.includes(benefit.id) ||
                              (eventRecord?.benefits[0]?.id &&
                                eventRecord.benefits
                                  .map((id: any) => id.id)
                                  ?.includes(benefit.id))
                          ),
                          restrictions: layout?.restrictions.filter(
                            (restriction: any) =>
                              eventRecord.restrictions?.includes(
                                restriction.id
                              ) ||
                              (eventRecord?.benefits[0]?.id &&
                                eventRecord.restrictions
                                  .map((id: any) => id.id)
                                  ?.includes(restriction.id))
                          ),
                          marketplaces: event?.event_exchanges?.filter(
                            (exchange: any) =>
                              event?.selected_event_exchanges?.includes(
                                exchange.exchange_id
                              )
                          ),
                          card: generateUniqueId(),
                          errors: {},
                          visibility: visibility,
                          ticketsInHands:
                            eventRecord?.quantity_available ===
                            (eventRecord?.listing_ticket_files?.length ||
                              eventRecord?.mobile_links?.length)
                              ? false
                              : eventRecord?.ticketsInHands,
                        };
                      }
                    })
                  : []
                ).filter((eventRecord) => eventRecord !== undefined),
              };
            })
            .filter(
              (event: any) =>
                isArray(event?.eventRecords) && event?.eventRecords.length > 0
            );
          return tempEvents;
        });
      } else {
        setTableAlert(true);
        setTableAlertMsg("Select a row to Clone");
        setTableAlertConfirm(false);
      }
    };

    const footerCondition =
      tableAlert === false && isTableEditable?.length === 0;

    useEffect(() => {
      if (list && list.length > 0) {
        const events: Array<Event> = list;

        let countSelected = 0;
        let published = 0;
        let unpublished = 0;
        events?.map((event) => {
          return Array.isArray(event["eventRecords"])
            ? event["eventRecords"].map((eventRecord: EventFields) => {
                if (eventRecord?.selected === true) {
                  countSelected++;
                }
                if (
                  eventRecord?.selected === true &&
                  eventRecord?.status === "yes"
                ) {
                  published++;
                }
                if (
                  eventRecord?.selected === true &&
                  eventRecord?.status === "no"
                ) {
                  unpublished++;
                }
              })
            : [];
        });

        if (countSelected > 0) {
          setTableAlertMsg(
            `Delete ${countSelected} listing${countSelected > 1 ? "s" : ""}? `
          );
          setPublishedAlertMsg(
            `${published > 0 ? published + " Published" : ""}${
              published && unpublished ? "," : ""
            } ${unpublished > 0 ? +unpublished + " Unpublished" : ""}`
          );
        }
        // new condtion
        if (countSelected === 0 && tableAlertConfirm) {
          setTableAlert(false);
          setTableAlertConfirm(false);
        }

        if (countSelected > 0 && tableAlertConfirm === false) {
          setTableAlert(false);
        }
        setListingCount(countSelected);

        const isOpenAnyEvent = events?.filter(
          (obj: any) => obj?.isOpen === true
        );

        if (isOpenAnyEvent) {
          setCheckEventIsOpen(isOpenAnyEvent);
        }
      }
    }, [list]);

    // HANDLE CANCEL EDIT FUNCTION
    const handleCancelEdit = () => {
      layout.setIsTableEditable([]);
      layout.setTableDataEditChanging([]);

      setList((current: any) => {
        return current?.map((event: any, eIndex: number) => {
          let eventRecord = event?.eventRecords?.map(
            (record: any, rIndex: number) => {
              let isSelected =
                layout.isTableEditable?.[eIndex]?.["eventRecords"]?.[rIndex]?.[
                  "selected"
                ];
              if (isSelected && record?.selected) {
                const editableValue: any =
                  layout.isTableEditable?.[eIndex]?.["eventRecords"]?.[rIndex];
                return {
                  ...editableValue,
                  selected: false,
                  loader: false,
                  isDisabled: false,
                  isInputChange: record?.isInputChange
                    ? record?.isInputChange
                    : false,
                  // replacible: {},
                  requireSelectAll: {},
                };
              } else {
                return record;
              }
            }
          );

          // CHECK IF ANY SELECTED LEFT - IN CASE USER GET ALL LISTING FROM HEADER SELECT ALL BTN
          let isAnySelected = eventRecord?.some(
            (record: any) => record?.selected
          );
          if (isAnySelected) {
            eventRecord = eventRecord?.map((record: any) => {
              return {
                ...record,
                selected: false,
              };
            });
          }
          return {
            ...event,
            eventRecords: eventRecord,
          };
        });
      });
    };

    const instantLocalValidation = useCallback(
      (event: any, eventRecord: any) => {
        let fieldErrors: any = [];

        if (eventRecord?.price_per_exchange) {
          if (Array.isArray(eventRecord?.ppePartners)) {
            eventRecord.ppePartners.forEach((partner: any) => {
              if (
                partner.value.value &&
                Number(partner.value.value) <= 0 &&
                partner?.is_hidden === false
              ) {
                fieldErrors["PPP"] = `PPP fields are required more than zero`;
                fieldErrors[
                  partner?.name
                ] = `${partner?.name} value field required more than zero`;
              }
            });
          }
        }

        const isHoldTicket =
          eventRecord?.hold_tickets?.length > 0 &&
          (eventRecord?.listing_ticket_files?.length > 0 ||
            eventRecord?.mobile_links?.length > 0) &&
          Number(eventRecord?.quantity_available) !==
            (Number(eventRecord?.listing_ticket_files?.length) ||
              Number(eventRecord?.mobile_links?.length));

        const isUploadedTicketLessWithQuantity =
          eventRecord?.quantity_available &&
          eventRecord?.quantity_available <
            (eventRecord?.listing_ticket_files?.length ||
              eventRecord?.mobile_links?.length);

        const faceValueCheck =
          Number(eventRecord?.face_value) <= 0 ||
          (typeof eventRecord?.face_value === "object" &&
            Number(eventRecord?.face_value?.value) <= 0);

        const processValueCheck =
          Number(eventRecord?.sell_price) <= 0 ||
          (typeof eventRecord?.sell_price === "object" &&
            Number(eventRecord?.sell_price?.value) <= 0 &&
            !eventRecord?.ppe);

        !eventRecord?.ticket_type &&
          (fieldErrors = {
            ...fieldErrors,
            ticket_type: "Ticket type field is required",
          });

        (!eventRecord?.quantity_available ||
          isUploadedTicketLessWithQuantity ||
          isHoldTicket) &&
          (fieldErrors = {
            ...fieldErrors,
            quantity_available:
              isUploadedTicketLessWithQuantity || isHoldTicket
                ? TicketError
                : "Quantity field is required",
          });

        (eventRecord?.ticket_type === "paper" ||
          eventRecord?.ticket_type?.id === "paper") &&
          eventRecord?.delivery_options?.length > 0 &&
          eventRecord?.delivery_options?.map((singleDelivery: any) => {
            let price =
              eventRecord?.[singleDelivery?.id] != undefined
                ? eventRecord?.[singleDelivery?.id]
                : Number(singleDelivery?.price);
            (!price || price <= 0) &&
              (fieldErrors = {
                ...fieldErrors,
                [singleDelivery?.id]:
                  layout.deliveryType?.find(
                    (item: any) => singleDelivery?.id === item?.id
                  )?.type + " field is required",
              });
          });

        !eventRecord?.category &&
          event?.categories &&
          Object.keys(event?.categories).length > 0 &&
          (fieldErrors = {
            ...fieldErrors,
            category: "Category value field is required",
          });

        (!eventRecord?.face_value ||
          (typeof eventRecord?.face_value === "object" &&
            eventRecord?.face_value?.value === "")) &&
          (fieldErrors = {
            ...fieldErrors,
            face_value: "Face value field is required",
          });

        //  new
        faceValueCheck &&
          (fieldErrors = {
            ...fieldErrors,
            face_value: "Face value field is required more than zero",
          });

        (!eventRecord?.sell_price ||
          (typeof eventRecord?.sell_price === "object" &&
            eventRecord?.sell_price?.value === "")) &&
          !eventRecord?.ppe &&
          (fieldErrors = {
            ...fieldErrors,
            sell_price: "Proceed value field is required",
          });

        // new

        processValueCheck &&
          (fieldErrors = {
            ...fieldErrors,
            sell_price: "Proceed value field is required more than zero",
          });

        // !eventRecord?.section &&
        //   eventRecord?.category?.sections &&
        //   Object.keys(eventRecord?.category?.sections).length > 0 &&
        //   (fieldErrors = {
        //     ...fieldErrors,
        //     section: "Section value field is required",
        //   });
        !eventRecord?.split_type &&
          (fieldErrors = {
            ...fieldErrors,
            splitType: "Split Type value field is required",
          });
        !eventRecord?.MULTIPLES &&
          eventRecord?.splitType?.id === "MULTIPLES" &&
          (fieldErrors = {
            ...fieldErrors,
            MULTIPLES: "Sell in Multiples Field is required",
          });

        !eventRecord?.sub_ticket_type?.id &&
          eventRecord?.ticketType?.id === "eticket" &&
          (fieldErrors = {
            ...fieldErrors,
            sub_ticket_type: "Sub Ticket type Field is required",
          });

        (eventRecord?.ticket_type === "paper" ||
          eventRecord?.ticket_type?.id === "paper") &&
          eventRecord?.delivery_options?.length > 0 &&
          eventRecord?.delivery_options?.map((singleDelivery: any) => {
            let price =
              eventRecord?.[singleDelivery?.id] != undefined
                ? Number(eventRecord?.[singleDelivery?.id])
                : Number(singleDelivery?.price);
            (!price || price <= 0) &&
              (fieldErrors = {
                ...fieldErrors,
                [singleDelivery?.id]:
                  layout.deliveryType?.find(
                    (item: any) => singleDelivery?.id === item?.id
                  )?.type + " field is required more than zero",
              });
          });

        return fieldErrors;
      },
      [events]
    );

    const setLocalValidationToEventRecords = useCallback((id: any) => {
      let success = true;
      setList((events: any) => {
        return events.map((event: any) => {
          return {
            ...event,
            eventRecords: Array.isArray(event["eventRecords"])
              ? event["eventRecords"].map(
                  (eventRecord: any, eventRecordKey: number) => {
                    if (eventRecord?.id === id) {
                      let fieldErrors: any = instantLocalValidation(
                        event,
                        eventRecord
                      );
                      success =
                        Object.keys(fieldErrors).length > 0 ? false : true;
                      let errors: any = [
                        {
                          errors: [
                            ...(eventRecord?.errors &&
                            _.size(eventRecord?.errors) > 0
                              ? eventRecord?.errors?.[0]?.errors
                              : []),
                            ...(fieldErrors && _.size(fieldErrors) > 0
                              ? Object.keys(fieldErrors)
                              : []),
                          ],
                          exchange: eventRecord?.errors?.[0]?.exchange,
                        },
                      ];
                      return {
                        ...eventRecord,
                        fieldErrors: fieldErrors,
                        errors: errors,
                      };
                    } else {
                      return eventRecord;
                    }
                  }
                )
              : event["eventRecords"],
          };
        });
      });
      return !success;
    }, []);

    const updateVisibility = (data: any) => {
      let vis: any;
      let oldvis: any;

      setList((prev: any) => {
        return prev.map((event: any) => {
          return {
            ...event,
            eventRecords: event.eventRecords?.map((eventRecord: any) => {
              if (eventRecord.id === data.id) {
                vis = eventRecord.visibility;
                oldvis = eventRecord.oldVisibility;
                return {
                  ...eventRecord,
                  visibility: 0,
                  oldVisibility: 0,
                  loader: false,
                  selected: false,
                  inputChange: false,
                };
              } else {
                return eventRecord;
              }
            }),
          };
        });
      });

      setTimeout(() => {
        setList((prev: any) => {
          const updatedEvents = prev.map((event: any) => {
            const updatedEventRecords = event.eventRecords?.map(
              (eventRecord: any) => {
                if (eventRecord.id === data.id) {
                  return {
                    ...eventRecord,
                    visibility: vis,
                    oldVisibility: 0,
                  };
                } else {
                  return eventRecord;
                }
              }
            );

            return {
              ...event,
              eventRecords: updatedEventRecords,
            };
          });

          return updatedEvents;
        });
      }, 300);
    };

    const updateRecord = async (
      event: any,
      eventRecord: any,
      onlyUpdate: Boolean = true
    ) => {
      //CHECK LOCAL VALIDATION
      if (!(await setLocalValidationToEventRecords(eventRecord?.id))) {
        return true;
      } else {
        setUpdateAllFlag(true);
        setList((prev: any) => {
          return prev.map((event2: any) => {
            if (event2?.id === event?.id) {
              return {
                ...event2,
                eventRecords: event2?.eventRecords?.map((item: any) => {
                  if (item?.id === eventRecord?.id) {
                    return {
                      ...item,
                      loader: true,
                    };
                  } else {
                    return item;
                  }
                }),
              };
            }

            return event2;
          });
        });

        setTimeout(() => {
          // setIsLoading(true);
          setLocalValidationToEventRecords(eventRecord?.id);
          eventRecord.status = onlyUpdate
            ? eventRecord.status
            : eventRecord.status === "no"
            ? "yes"
            : "no";
          showAlert(
            "Your listing has successfully been scheduled for an update",
            false
          );
          UpdateInventory(
            setEventRecordPayload(
              event,
              { ...eventRecord, price_per_exchange: 0 } //REMOVE PPE CHANGE
            )
          ).then((response: any) => {
            let responseData = response?.data?.payload?.post_data;
            setUpdateAllFlag(false);
            responseData &&
              response?.errors &&
              showAlert(Object.values(response?.errors)?.toString(), true);
            // responseData
            //   ? showAlert(response.message, response?.data?.status ? false : true)
            //   : showAlert(Object.values(response?.errors)?.toString(), true);

            updateVisibility({
              ...eventRecord,
              ...(response?.data?.payload?.is_ticket_type_change === 1
                ? {
                    listing_ticket_files: [],
                    ticketFiles: [],
                    mobile_links: [],
                  }
                : {}),
            });
          });
        }, 600);
      }
    };

    const updateState = (
      bulkUpdateStatus: any,
      inputChange: boolean = false,
      isSelected: boolean = false,
      payloadId: any = false,
      externalArr: any,
      ticketTypeChangedIds: any = {}
    ) => {
      setList((prev: any) => {
        return prev?.map((event: any) => {
          return {
            ...event,
            eventRecords: event?.eventRecords?.map((record: any) => {
              // MATCHED THE RECORDS
              let matchedRecord = externalArr?.find(
                (item: any) => item?.listing_id && record?.id
              );
              if (matchedRecord) {
                // UPDATE THE ID IF CHANGED IN PAYLOAD
                payloadId &&
                  Object.entries(payloadId).forEach(([key, value]) => {
                    if (record.id === key && record.id !== value) {
                      record.id = value;
                    }
                  });
                return {
                  ...record,
                  bulkUpdate: bulkUpdateStatus,
                  ...(!isSelected && { selected: false }),
                  ...(!inputChange &&
                    record?.errors?.length <= 0 && { isInputChange: false }),
                  ...(ticketTypeChangedIds?.[record.id] === 1 && {
                    listing_ticket_files: [],
                    ticketFiles: [],
                    mobile_links: [],
                  }),
                };
              } else {
                return record;
              }
            }),
          };
        });
      });
    };

    const mergeArraysAndUpdateState = (oldArray: any, newArray: any) => {
      const updatedState = [...oldArray].map((item) => {
        const newItem = newArray.find((newItem: any) => newItem.id === item.id);
        if (newItem) {
          return {
            ...item,
            ...newItem,
          };
        } else {
          return item;
        }
      });
      setList(updatedState);
    };

    // HANDLE UPDATE ALL
    const handleUpdateAll = useCallback(async () => {
      setCurrentRecordId(undefined);
      setTimeout(() => {
        layout.setisBulkActionLoader(true);
      }, 500);

      // ALL DATA AND TO CREATE A PAYLOAD
      let result: any = [];

      // GET ONLY THOSE DATA WHICH ARE PASSED THE VALIDATIONS
      let validPassedData: any = [];
      let InvalidData: any = [];

      // CREATE PAYLOAD
      result = await bulkPayloadCreate(list, "isInputChange");
      // CHECK ALL VALIDATIONS AND DISPLAY THOSE ON LISTINS
      await Promise.all(
        result.map(async (item: any) => {
          let fieldValueErrors = await instantLocalValidation(
            item?.eventCategory,
            { ...item }
          );
          let status = Object.keys(fieldValueErrors).length > 0;
          if (!status) {
            _.size(item?.fieldErrors) > 0 &&
              (await setLocalValidationToEventRecords(item?.listing_id));
            await validPassedData.push(item);
          } else {
            await setLocalValidationToEventRecords(item?.listing_id);
            let listing_quality = visiblityCountForPayload(item?.visibility);
            InvalidData.push({
              ...item,
              listing_quality,
              fieldErrors: fieldValueErrors,
            });
          }
        })
      );
      let batchSize: any = 100;
      const dataLength = validPassedData.length;
      const invalidDataLength = InvalidData.length;

      // IF ALL SELECTED FEILDS HAS ERROR
      if (invalidDataLength > 0) {
        // layout.setisBulkActionLoader(false);
        const firstInvalidData = InvalidData[0];
        const firstInvalidDataEvent = events
          .filter((ev: any) => ev.id === firstInvalidData.event_id)
          .map((f: any) => f.eventRecords)[0];
        const listingId = firstInvalidDataEvent.findIndex(
          (d: any) => d.id === firstInvalidData.listing_id
        );
        const eventIndex = events.findIndex(
          (ev: any) => ev.id === firstInvalidData.event_id
        );
        let firstKey = firstInvalidData?.fieldErrors
          ? Object.keys(firstInvalidData?.fieldErrors)?.[0]
          : "";
        await setCurrentRecordId({
          recordIndex: listingId,
          eventIndex: eventIndex,
        });
        setTimeout(async () => {
          var inputField: any = document.getElementById(
            `${firstKey}-${firstInvalidData.event_id}-${listingId}`
          );
          const marketInsightBtn = document.getElementById(
            `EventInsight${firstInvalidData.event_id}`
          );
          if (
            marketInsightBtn &&
            marketInsightBtn?.getAttribute("aria-expanded") === "true"
          ) {
            marketInsightBtn?.click();
          }

          const mainCollapseBtn = document.querySelector<HTMLButtonElement>(
            `[data-tooltip-id="collapseIcon-${firstInvalidData.event_id}"]`
          );

          if (
            mainCollapseBtn &&
            mainCollapseBtn.getAttribute("aria-expanded") === "false"
          ) {
            mainCollapseBtn?.click();
          }

          const openEvents = list?.filter((obj: any) => obj?.isOpen === true);

          openEvents.forEach((event: any) => {
            if (event?.id !== firstInvalidData?.event_id) {
              const eventCollpaseBtn =
                document.querySelector<HTMLButtonElement>(
                  `[data-tooltip-id="collapseIcon-${event?.id}"]`
                );
              if (
                eventCollpaseBtn &&
                eventCollpaseBtn.getAttribute("aria-expanded") === "true"
              ) {
                eventCollpaseBtn.click();
              }
            }
          });

          // Wait for some second
          await delay(800);

          if (!inputField) {
            layout.setVirtuosoRecordIndex({
              virtuosoId: listingId,
              eventId: firstInvalidData.event_id,
            });
          }

          await delay(1000); // Wait for 1 second

          setTimeout(async () => {
            let inputFields: any = document.getElementById(
              `${firstKey}-${firstInvalidData.event_id}-${listingId}`
            );

            // Use Promise to wait for 0.5 second
            const delay = (ms: any) =>
              new Promise((resolve) => setTimeout(resolve, ms));

            await delay(500); // Wait for 0.5 second

            if (inputFields) {
              inputFields.scrollIntoView({
                behavior: "auto",
                inline: "center",
                block: `center`,
              });
              showAlert("Please fix the errors on the listings", true);
            }
          }, 1000);
          layout.setisBulkActionLoader(false);
        }, 1000);
      }
      // IF ANY ONE IS PASSED FROM VALIDATION
      else {
        updateState(true, true, true, false, validPassedData);
        const alertMessage = `${dataLength} listing(s) has successfully been scheduled for an update`;
        showAlert(alertMessage, false);

        if (dataLength > batchSize) {
          const { mergedObject, is_ticket_type_change }: any =
            await processInBatches(validPassedData, batchSize);

          if (mergedObject) {
            updateState(
              false,
              false,
              false,
              mergedObject,
              validPassedData,
              is_ticket_type_change
            );
            setTimeout(() => {
              layout.setisBulkActionLoader(false);
            }, 10000);
          }
        } else {
          const response: any = await bulkUpdate(validPassedData);
          if (response?.data?.status) {
            let payloadId = response?.data?.payload;
            updateState(
              false,
              false,
              false,
              payloadId,
              validPassedData,
              response?.data?.is_ticket_type_change
            );
            layout.setisBulkActionLoader(false);
          }
        }
      }
    }, [list]);

    // HANDLE CONFIRM
    const handleConfirmEdit = () => {
      layout.setIsTableEditable([]);
      layout.setTableDataEditChanging([]);

      // events?.forEach((item: any) => {
      //   item?.eventRecords?.map((record: any) => {
      //     setLocalValidationToEventRecords(record?.id);
      //   });
      // });
      setTimeout(() => {
        setList((current: any) => {
          return current?.map((event: any) => {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map((record: any) => {
                return {
                  ...record,
                  // selected: false,
                  selected: record?.isInputChange ? true : false,
                  isDisabled: false,
                };
              }),
            };
          });
        });
      }, 300);
    };

    useEffect(() => {
      if (changedDataCount === 0) {
        setLoader(false);
        layout.setisBulkActionLoader(false);
      }
    }, [changedDataCount]);

    const isAnyLockOpen = events?.map((obj: any) =>
      obj?.eventRecords?.some((innerobj: any) => innerobj?.isLock === false)
    );
    const isCheckAnyOfLockOpen = isAnyLockOpen?.some(
      (item: any) => item === true
    );

    const alertCancel = (data: any) => {
      layout.SetIsDeleteActive(false);
      refreshEventsWhileActionPerform();
      setTableAlert(data);
    };

    return (
      <>
        <footer className="page_footer sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-[.1875rem] bg-white shadow-3xl   min-h-[3.1437rem] z-30">
          <button
            type="button"
            className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed bottom-[4.0625rem] right-0 transition duration-300 ${
              isVisible ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
            id="backtotop"
            onClick={scrollToTop}
          >
            <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
          </button>
          {tableAlert && (
            <div
              className={`flex w-full bg-white rounded overflow-auto whitespace-nowrap`}
            >
              {tableAlertConfirm ? (
                <div className="flex gap-2.5 gap-x-5 justify-end items-center w-full flex-row-reverse">
                  <div>
                    <span className="text-sm13 font-medium mr-2 block">
                      {tableAlertMsg}
                      <span className="text-gray-500 ml-2">
                        {publishedAlertMsg}
                      </span>
                    </span>
                  </div>

                  <div className="flex items-center ">
                    <button
                      className="inline-flex group items-center text-violet-800 border transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 hover:border-indigo-500 hover:text-white"
                      type="button"
                      onClick={(e: any) => {
                        alertCancel(false);
                      }}
                    >
                      {" "}
                      Cancel
                    </button>
                    <button
                      className="ml-2 bg-rose-500  inline-flex group items-center text-white   
                  transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 "
                      type="button"
                      onClick={(e: any) => {
                        setTableAlert(false);
                        confirmEventFieldsDelete();
                      }}
                      ref={btnRef}
                      id={`delete`}
                    >
                      <i className="fas fa-solid fa-trash mr-1.5"></i>
                      Delete
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <IKTSVG
                    svgClassName="fill-rose-500 min-w-[1rem] mt-0.5 mr-2"
                    path={WarningSVG}
                  />
                  <span className="text-sm font-medium mr-2 text-rose-500">
                    {tableAlertMsg}
                  </span>
                  <button
                    className="ml-auto mt-0.5 p-1 h-full"
                    type="button"
                    onClick={(e: any) => alertCancel(false)}
                    id="Cross-alert"
                  >
                    <Tooltip
                      anchorId="Cross-alert"
                      content={"Close"}
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10 mt-1.5"
                    />
                    <IKTSVG
                      svgClassName="fill-violet-800 hover:fill-indigo-500 transition"
                      path={CrossSVF}
                    />
                  </button>
                </>
              )}
            </div>
          )}

          {isTableEditable && isTableEditable.length > 0 && (
            <>
              <div className="flex whitespace-nowrap overflow-auto items-center gap-2 my-2">
                <button
                  type="button"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  onClick={() => {
                    handleCancelEdit();
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="inline-flex items-center text-white bg-green-500 hover:bg-indigo-500 transition
font-medium rounded text-sm13 px-2.5 py-0.5 "
                  onClick={handleConfirmEdit}
                >
                  <IKTSVG
                    className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-white"
                    path={EditSquareSVG}
                  />
                  Confirm
                </button>

                <span className="text-sm13 font-medium pl-3 leading-4">
                  Editing{" "}
                  {layout.countSelectedEdit?.events > 1 &&
                    `${layout.countSelectedEdit?.events} events with`}{" "}
                  {changedSelectedCount(events)} listing
                  {changedSelectedCount(events) > 1 && `s`}
                  <span className="pl-2 ">
                    {bulkTableChangedFields?.map((item: any, index: number) => {
                      let formattedString =
                        item === "Ticketsinhands"
                          ? "Ticket in hands"
                          : item === "Sell price"
                          ? "Proceed price"
                          : item;
                      return (
                        <span
                          className="text-xs text-gray-500 font-medium"
                          key={index}
                        >
                          &nbsp;
                          {index === bulkTableChangedFields?.length - 1 &&
                          formattedString
                            ? formattedString
                            : formattedString + ", "}
                        </span>
                      );
                    })}
                  </span>
                </span>
              </div>
            </>
          )}

          {footerCondition && !isOpenTicketPopUp && (
            <div
              className={`flex justify-between w-full overflow-auto whitespace-nowrap gap-x-2.5`}
            >
              {layout?.selectAllConfirmation ? (
                <>
                  <div
                    className={`${
                      layout?.selectAllLoader ? "pointer-events-none" : ""
                    } flex  items-center gap-2 my-2`}
                  >
                    <div className="flex items-center gap-2.5  text-sm13">
                      <button
                        type="button"
                        className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                      bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
                        onClick={() => layout.setSelectAllConfirmation(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
                      ${
                        layout?.selectAllLoader
                          ? "bg-gray-200 text-gray-400 "
                          : "bg-violet-500 hover:bg-indigo-500 text-white hover:text-white"
                      }
                      `}
                        onClick={(e: any) => handleLoadAllListing(e)}
                      >
                        Select all {openEvent?.listingsCount} listings
                      </button>
                      <span>
                        {openEvent?.count === 1
                          ? ` in ${openEvent?.count} expanded event`
                          : ` in ${openEvent?.count} expanded events`}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={`flex  items-center gap-2 my-2`}>
                    <div className="flex  items-center gap-2 my-0.5">
                      {/* SELECT */}
                      <div className="inline-flex items-center text-violet-800 transition font-medium rounded px-2.5">
                        {selectAllLoader ? (
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition animate-spin"
                            path={processSVG}
                          />
                        ) : (
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            checked={selectAll && listingCount > 0}
                            disabled={
                              isDisabled ||
                              countTotalListing <= 0 ||
                              checkEventIsOpen?.length === 0
                                ? true
                                : false
                            }
                            className={` 
                            ${
                              isDisabled ||
                              countTotalListing <= 0 ||
                              checkEventIsOpen?.length === 0
                                ? "bg-gray-100 pointer-events-none"
                                : ""
                            }
                            w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 ${
                              isDisabled ||
                              (countTotalListing <= 0 ||
                              checkEventIsOpen?.length === 0 ||
                              layout?.selectAllLoader ||
                              (isCheckAnyOfLockOpen === false && isGodAdmin)
                                ? "bg-gray-100 pointer-events-none"
                                : "")
                            }
                         
                          `}
                            onChange={(data) => {
                              // handleSelectAll(data, false)
                              layout.setSelectAllConfirmation(true);
                            }}
                          />
                        )}
                        <label
                          htmlFor="default-checkbox"
                          className={`cursor-pointer ml-2 text-sm13 font-medium  dark:text-gray-300 ${
                            isDisabled ||
                            countTotalListing <= 0 ||
                            checkEventIsOpen?.length === 0 ||
                            layout?.selectAllLoader
                              ? "text-gray-400 pointer-events-none"
                              : "text-violet-800"
                          }
                        ${
                          isCheckAnyOfLockOpen === false &&
                          isGodAdmin &&
                          " text-violet-800 pointer-events-none"
                        }
                        `}
                        >
                          Select all
                        </label>
                      </div>

                      {/* De-select */}
                      <button
                        type="button"
                        className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                          (isDisabled ||
                            listingCount < 1 ||
                            layout?.selectAllLoader) &&
                          "pointer-events-none !text-gray-400"
                        }`}
                        onClick={(data) => {
                          handleSelectAll(data, true);
                          setSelectAll(false);
                        }}
                      >
                        <IKTSVG
                          className={`${
                            isDisabled ||
                            listingCount < 1 ||
                            layout?.selectAllLoader
                              ? "fill-gray-400"
                              : "fill-violet-500 "
                          } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                          path={CloseSquareSVG}
                        />
                        Deselect
                      </button>
                      {/* )} */}

                      {/* CLONE EVETS TO ADD INVENTORY */}
                      <button
                        type="button"
                        className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                          (isDisabled ||
                            isGodAdmin ||
                            listingCount < 1 ||
                            layout?.selectAllLoader) &&
                          "!text-gray-400 pointer-events-none"
                        } `}
                        onClick={() => handleCloneEvent()}
                      >
                        <IKTSVG
                          className={`${
                            isDisabled ||
                            isGodAdmin ||
                            listingCount < 1 ||
                            layout?.selectAllLoader
                              ? "fill-gray-400"
                              : "fill-violet-500 "
                          }  min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                          path={CloneSquareSVG}
                        />
                        Clone
                      </button>

                      {/* Edit */}
                      <button
                        type="button"
                        className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                          (isDisabled ||
                            listingCount < 1 ||
                            layout?.selectAllLoader) &&
                          "!text-gray-400 pointer-events-none"
                        } max-md:hidden `}
                        onClick={(data: any) => handleEditEvent(data, true)}
                      >
                        <IKTSVG
                          className={`${
                            isDisabled ||
                            listingCount < 1 ||
                            layout?.selectAllLoader
                              ? "fill-gray-400"
                              : "fill-violet-500 "
                          } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                          path={EditSquareSVG}
                        />
                        Edit
                      </button>
                      {/* Delete */}

                      <button
                        type="button"
                        className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                          (isDisabled ||
                            listingCount < 1 ||
                            layout?.selectAllLoader) &&
                          "!text-gray-400 pointer-events-none"
                        }`}
                        onClick={() =>
                          flag === false && handleEventFieldsDelete()
                        }
                      >
                        <IKTSVG
                          className={`${
                            isDisabled ||
                            listingCount < 1 ||
                            layout?.selectAllLoader
                              ? "fill-gray-400"
                              : "fill-violet-500 "
                          } min-w-[0.625rem] max-w-[0.63rem]  mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                          path={DeleteSquareSVG}
                        />
                        Delete
                      </button>

                      <button
                        type="button"
                        onClick={confirmEventFieldsDelete}
                        className="hidden"
                      >
                        delete
                      </button>
                      {changedSelectedCount(events) > 0 && (
                        <div>
                          <p className="font-medium text-sm13 ml-2.5 flex items-center ">
                            <IKTSVG
                              svgClassName="w-[.75rem] h-2.5 mr-2"
                              path={ListingLinesSVG}
                            ></IKTSVG>
                            {changedSelectedCount(events)} selected in{" "}
                            {_.size(eventCountSelected) === 1
                              ? `${_.size(eventCountSelected)} event`
                              : `${_.size(eventCountSelected)} events`}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <CollapseButton
                      list={list}
                      setList={setList}
                      isDisabled={isDisabled}
                      publishStatus={publishStatusId}
                      setSelectAll={setSelectAll}
                      checkEventIsOpen={checkEventIsOpen}
                    />

                    {/* UPDATE ALL EVENTRECORDS */}
                    {changedInputValues(list) >= 2 && (
                      <button
                        disabled={layout?.isBulkActionLoader}
                        type="button"
                        className={`anim-fade ml-2 inline-flex items-center  transition
font-medium rounded text-sm13 px-2.5 py-0.5 ${
                          (changedInputValues(list) > 0 && isDisabled) ||
                          isDisabled ||
                          bulkUploadCount(list) > 0
                            ? `bg-gray-100 pointer-events-none text-gray-400`
                            : `bg-green-500 hover:bg-indigo-500 text-white`
                        }`}
                        onClick={async () => {
                          handleUpdateAll();
                        }}
                      >
                        <IKTSVG
                          className={`min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center  ${
                            isDisabled ? "fill-gray-400" : "fill-white"
                          } ${
                            layout?.isBulkActionLoader ? "animate-spin" : ""
                          }`}
                          path={
                            layout?.isBulkActionLoader ? processSVG : SaveSVG
                          }
                          svgClassName="w-[.75rem] h-[.75rem]"
                        />
                        Update all
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </footer>
      </>
    );
  }
);

export default Footer;
